import { fetchWithToken } from '../utils/Keycloak'

const logBackend = 'https://api.hublot.io/parse/'


export default {
  get () {
    return fetchWithToken(logBackend + 'Log', {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(response => {
      return response
    }).catch((err) => {
      console.error(err)
      return []
    })
  },
  getRouting () {
    return fetchWithToken(logBackend + 'Routing', {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).then(response => {
      return response
    }).catch((err) => {
      console.error(err)
      return []
    })
  }
}
