<template>
  <v-row align="center">
    <v-navigation-drawer :value="navigationDrawerVisibility" width="250" app
      :temporary="isMobile" :absolute="isMobile" :fixed="!isMobile" @input="switchNavigationDrawerVisibility"
      class="background-navigation">
      <div>
        <v-list class="header-nav">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon color="secondary" size="35">
                account_circle
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-row class="header-info" no-gutters>
                <v-list-item-title style="color:var(--v-primary-base);font-size: 1rem;font-weight:600; text-align:left;">{{ nom }}
                </v-list-item-title>
                <div class="logout " @click="logout">
                  <p stlye="font-size:0.7rem; margin-top:2%;"> Se déconnecter
                    <v-icon small class="pl-1" color="secondary">
                      logout
                    </v-icon>
                  </p>
                </div>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div class="content-nav">
        <v-row class="d-none d-sm-flex pa-3">
          <SelectCustomer />
        </v-row>
        <v-list class="color-unset">
          <template v-for="(item, i) in currentDrawer">
          <v-list-item  :to="item.to" active-class="active-color"  :key="'drawer' + i">
            <v-list-item-action>
              <v-icon>{{item.icon}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{item.title}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        </v-list>
      </div>
    </v-navigation-drawer>
  </v-row>
</template>

<script>
import { getUserProfile } from '../utils/Keycloak'
import InputComponentMixin from '../mixins/InputComponentMixin'
import SelectCustomer from './SelectCustomer'
import navigationDrawerHublot from '@/assets/definitions/navigationDrawerHublot.json';
import navigationDrawerCarnot from '@/assets/definitions/navigationDrawerCarnot.json';
import { mapState } from 'vuex'
import Vue from 'vue'
export default {
  data () {
    return { customer: {}, drawer: false, nom: '' }
  },
  components: {
    SelectCustomer
  },
  mixins: [InputComponentMixin],
  methods: {
    switchNavigationDrawerVisibility (state) {
      this.$store.dispatch('setNavigationDrawerVisibility', state)
    },
    logout: function () {
      Vue.prototype.$keycloak.logoutFn()
    },

  },
  computed: {
    currentDrawer(){
      if(this.app === 'carnot')
        return navigationDrawerCarnot
      else
        return navigationDrawerHublot
    },
    isMobile () {
      return window.innerWidth < 600
    },
    ...mapState({
      app : state => state.home.app,
      navigationDrawerVisibility: state => state.home.navigationDrawerVisibility,
      backgroundcolor: state => state.home.color
    })
  },
  created: function () {
    getUserProfile().then(profile => {
      this.nom = profile.firstName + ' ' + profile.lastName
    })

  }
}
</script>

<style lang="scss">
@import "../assets/scss/vars";

.v-list .v-list-item--active {
  background: #fafafa !important;
  font-size: 16px !important;
}
</style>

<style lang="scss" scoped>
.padding-icon {
  padding-right: 20px;
}

.color-unset {
  margin-top: -15%;

  .active-color {

    i,
    .v-list-item__title {
      color: var(--v-primary-base) !important;
    }

    .v-list-item__title {
      font-size: 18px;
      font-weight: 500;
    }
  }

  text-align: left;

  a,
  i {
    color: #fafafa !important;
  }
}

.background-navigation {
  background-size: cover;
  background: var(--v-primary-base);
  background-repeat: no-repeat;
}

.logout:hover {
  cursor: pointer;
  opacity: 0.6;
}

.header-nav {
  background-color: #f5f5f5 !important;
  height: 64px;
  position: fixed;
  padding: 0 !important;
  text-align: left;
  z-index: 2;
}

.content-nav {
  position: relative;
  margin-top: 30%;
}

.header-info {
  div {
    margin: 0 !important;
  }
}
</style>
