<template>

  <v-app-bar clipped-right fixed>
    <v-icon medium class="hidden-lg-and-up menuIcon" @click="switchNavigationDrawerVisibility">menu</v-icon>
    <v-app-bar-title class="d-md-none d-lg-flex hublot-font">
      <v-row>
        <img src="../assets/logo.png" width="110px" class="d-md-flex d-sm-none d-none d-sm-flex" />
      </v-row>
    </v-app-bar-title>
    <v-spacer />
    <img :src="logoHublot" @click="switchTo('hublot')" class="d-md-flex d-sm-none d-none d-sm-flex mr-10"
      width="150px">
    <img :src="logoCarnot" @click="switchTo('carnot')" class="d-md-flex d-sm-none d-none d-sm-flex"
      width="150px" />
    <div>
      <v-row align="center" justify="center" class="hidden-lg-and-up">
        <v-col cols="10" md="8">
          <v-row align="center" justify="center" class="select-customer-mobile">
            <SelectCustomer />
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-spacer />
    <div class="d-md-flex d-sm-none d-none d-sm-flex">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="icons" v-bind="attrs" v-on="on" href="https://www.sellsy.fr/" target="_blank">
            <v-icon>language</v-icon>
          </v-btn>
        </template>
        <span>Sellsy</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="icons" v-bind="attrs" v-on="on" href="https://grafana.hublot.io" target="_blank">
            <v-icon>multiline_chart</v-icon>
          </v-btn>
        </template>
        <span>Grafana</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="icons" v-bind="attrs" v-on="on" href="https://hublot.hexnodemdm.com" target="_blank">
            <v-icon>phonelink_setup</v-icon>
          </v-btn>
        </template>
        <span>Hexnode MDM</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="icons" v-bind="attrs" v-on="on" href="https://parse.hublot.io/login" target="_blank">
            <v-icon>report</v-icon>
          </v-btn>
        </template>
        <span>Parse (Logs)</span>
      </v-tooltip>
    </div>
  </v-app-bar>
</template>
<script>
import { mapState } from 'vuex'
import SelectCustomer from './SelectCustomer'
export default {
  data () {
    return {
      logoHublot : 'logo-hublot.png',
      logoCarnot : 'logo-carnot-grey.png',
      themes: {
        hublot : {
          primary: '#376374',
          secondary: '#8AC3D5'
        },
        carnot : {
          primary: '#9d0419',
          secondary: '#e44353'
        }
      }
    };
  },
  components: {
    SelectCustomer
  },
  methods: {
    switchNavigationDrawerVisibility () {
      this.$store.dispatch('setNavigationDrawerVisibility', true)
    },
    switchTo (app) {

      this.$vuetify.theme.themes.light.primary = this.themes[app].primary
      this.$vuetify.theme.themes.light.secondary = this.themes[app].secondary

      if(app === 'carnot'){
        this.logoHublot = 'logo-hublot-grey.png'
        this.logoCarnot = 'logo-carnot.png'
      }
      else {
        this.logoHublot = 'logo-hublot.png'
        this.logoCarnot = 'logo-carnot-grey.png'
      }
      this.$store.dispatch('setApp', app)
    }
  },
  computed: {
    ...mapState({
      navigationDrawerVisibility: state =>
        state.home.navigationDrawerVisibility,
      customers: state => state.customers.list,
      selectedCustomer: state => state.customers.selected
    })
  }
}
</script>
<style lang="scss">
.icons:hover {
  span {
    color: var(--v-secondary-base);
  }
}

.hidden-lg-and-up {
  .v-input {
    max-width: 250px !important;
  }

  span {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 1rem;
  }

  .v-text-field__details {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/scss/vars";

.menuIcon,
.hublot-font {
  margin-top: 1vh;
}

.selectCustomer {
  margin-top: 2vh;
  width: max-content;
}

.hublot-font {
  font-family: "Paytone One", sans-serif;
  font-size: 30px;
}

.transparent-toolbar {
  background: rgb(238, 238, 238);
}

.select-customer-mobile {
  padding-top: 10px !important;
}
</style>
