import { v4 as uuid } from 'uuid'
export default {
  state: {
    queue: []
  },
  mutations: {
    addNotification (state, notification) {
      state.queue = [notification, ...state.queue]
    },
    removeNotification (state, notification) {
      state.queue = state.queue.filter(notif => notif.id !== notification.id)
    }
  },
  actions: {
    addNotification ({ commit }, notification) {
      commit('addNotification', { ...notification, id: uuid() })
    },
    removeNotification ({ commit }, notification) {
      commit('removeNotification', notification)
    }
  }
}
