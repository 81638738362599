import config from '@/utils/ApiUtils'
import { fetchWithToken } from '../utils/Keycloak'
import downloadjs from 'downloadjs'


export default {
  get () {
    return fetchWithToken(config().sqlbackend, {
      method: 'GET'
    }).then((response) => response.json())
  },
  delete (item) {
    return fetchWithToken(config().sqlbackend, {
      method: 'DELETE',
      body: JSON.stringify(item)
    }).then((response) => {
      return response.json()
    })
  },
  download (sql) {
    return fetchWithToken(config().sqlbackend + 'download/' + sql, {
      method: 'GET'
    })
      .then((response) => response.blob())
      .then((file) => {
        downloadjs(file, 'script.sql')
      })
  },
  downloadContent (sql) {
    return fetchWithToken(config().sqlbackend + 'download/' + sql, {
      method: 'GET'
    })
      .then((response) => response.text())
  },
  upload (file, version) {
    const data = new FormData()
    data.append('file', file)
    data.append('version', version)
    return fetchWithToken(config().sqlbackend, {
      method: 'POST',
      body: data
    })
  },

  getClient (serial) {
    return fetchWithToken(config().sqlbackend + 'client', {
      headers: {
        serial: serial
      },
      method: 'GET'
    }).then((response) => response.json())
  },
  deleteClient (item) {
    return fetchWithToken(config().sqlbackend + 'client', {
      method: 'DELETE',
      body: JSON.stringify(item)
    }).then((response) => {
      return response.json()
    })
  },
  downloadClient (sql) {
    return fetchWithToken(config().sqlbackend + 'client/' + sql, {
      method: 'GET'
    })
      .then((response) => response.json())
      .then((file) => {
        return file
      })
  },
  uploadClient (file, version, serial, beta) {
    const data = new FormData()
    data.append('file', file)
    data.append('version', version)
    data.append('serial', serial)
    data.append('beta', beta)

    return fetchWithToken(config().sqlbackend + 'client', {
      method: 'POST',
      body: data
    })
  },
  checkQuery (scriptSQL, clientId) {
    const url = `${config().sqlbackend}check/${clientId}`
    return fetchWithToken(url, { method: 'POST', body: JSON.stringify(scriptSQL) },)
      .then((response) => {
        return response.json()
      })
  },

}
