import CustomerService from '@/services/CustomerService'

export default {
  state: {
    list: [],
    selected: {},
    infosLoader: true,
    sellsyCustomer: {},
    companies: [],
    taskLabels: [],
    addTaskDialog: false,
    taskDescription: '',
    taskOwnerName: '',
    taskSnackbarState: false,
    taskSnackbarMessage: '',
    taskSnackbarColor: '',
    customersAvailableDeviceCapacity: [],
    loader: false,
    progressionValue: 0
  },

  mutations: {
    setDeviceLoader (state, loader) {
      state.loader = loader
    },
    setProgressionValue(state, value) {
      state.progressionValue= value
    },
    setCustomers (state, customers) {
      state.list = customers
    },
    setSelectedCustomer (state, customer) {
      state.selected = customer
    },
    setCompanies (state, companies) {
      state.companies = companies
    },
    setTaskLabels (state, labels) {
      state.taskLabels = labels
    },
    setInfosLoader (state, bool) {
      state.infosLoader = bool
    },
    setSellsySelectedCustomer (state, sellsyCustomer) {
      state.sellsyCustomer = sellsyCustomer
    },
    setAddTaskDialog (state, bool) {
      state.addTaskDialog = bool
    },
    setTaskDescription (state, text) {
      state.taskDescription = text
    },
    setTaskOwnerName (state, text) {
      state.taskOwnerName = text
    },
    setTaskSnackbarState (state, bool) {
      state.taskSnackbarState = bool
    },
    setTaskSnackbarMessage (state, text) {
      state.taskSnackbarMessage = text
    },
    setTaskSnackbarColor (state, color) {
      state.taskSnackbarColor = color
    },
    setCustomersAvailableDeviceCapacity(state, capacities){
      state.customersAvailableDeviceCapacity = capacities
    }
  },
  actions: {
    setCustomers ({ commit }, customers) {
      commit('setCustomers', customers)
    },
    setSelectedCustomer ({ commit }, customer) {
      commit('setSelectedCustomer', customer)
    },
    setCompanies ({ commit }, companies) {
      commit('setCompanies', companies)
    },
    setTaskLabels ({ commit }, labels) {
      commit('setTaskLabels', labels)
    },
    setSellsySelectedCustomer ({ commit }, sellsyCustomerId) {
      commit('setInfosLoader', true)
      CustomerService.getSellsyCompanyById(sellsyCustomerId)
        .then((sellsyCustomer) => {
          commit('setSellsySelectedCustomer', sellsyCustomer)
        }).finally(() => {
          commit('setInfosLoader', false)
        })
    },
    setAddTaskDialog ({ commit }, bool) {
      commit('setAddTaskDialog', bool)
    },
    setTaskDescription ({ commit }, text) {
      commit('setTaskDescription', text)
    },
    setTaskOwnerName ({ commit }, text) {
      commit('setTaskOwnerName', text)
    },
    setTaskSnackbarState ({ commit }, bool) {
      commit('setTaskSnackbarState', bool)
    },
    setTaskSnackbarMessage ({ commit }, text) {
      commit('setTaskSnackbarMessage', text)
    },
    setTaskSnackbarColor ({ commit }, color) {
      commit('setTaskSnackbarColor', color)
    },
    setCustomersAvailableDeviceCapacity({dispatch, commit, state}){
      commit('setDeviceLoader', true)
      commit('setProgressionValue', 0)
      if(state.list.length > 0){
        dispatch('fetchCustomersAvailableDeviceCapacity')
      } else {
        CustomerService.get()
          .then((customers) => {
            dispatch('setCustomers', customers)
          })
          .then(()=>{
            dispatch('fetchCustomersAvailableDeviceCapacity')
          })
      }
    },
    fetchCustomersAvailableDeviceCapacity({dispatch, commit, state}){
      const clientsNumber = state.list.filter((customer) => customer.type === 'Client').length
      const waitingTime = clientsNumber * 25 + 325 //2,5 seconds by custommer 
      const delay = setInterval(() => {
        if (state.progressionValue === 100) {
          clearInterval(delay)
        } else {
          commit('setProgressionValue', state.progressionValue + 1)
        }
      }, waitingTime)
      CustomerService.getCustomersAvailableDeviceCapacity()
        .then((res)=>{
          const containsErrors = res.filter((device) => device.err).length > 0
          if(containsErrors){
            dispatch('addNotification', {text: 'Trop de requêtes vers Hexnode.', color: 'red'})
            clearInterval(delay)
          } else {
            const fivefirsts = res.filter((device, index) => index < 5)
            commit('setCustomersAvailableDeviceCapacity', fivefirsts)
          }
        })
        .then(()=>{
          commit('setProgressionValue', 0)
          commit('setDeviceLoader', false)
        })
        .catch((e) => {
          console.error(e);
          dispatch('addNotification', {text: 'Impossible de récupérer les informations des appareils sur Hexnode.', color: 'red'})
        });
    }
  }
}
