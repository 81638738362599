import config from '@/utils/ApiUtils'
import { fetchWithToken } from '@/utils/Keycloak'
import HeatmapService from '@/services/HeatmapService'
import groupby from 'lodash.groupby'

export default {
  getColorByClientId (clientId, endpoint) {
    const params = new URLSearchParams()
    params.append('clientid', clientId)
    const url = `${config().heatmapApi}/${endpoint}?${params.toString()}`
    return fetchWithToken(url, { method: 'GET' })
      .then(response => response.json())
      .then(clientResult => {
        return [{
          ...clientResult[0], endpoint
        }]
      })
  },
  getNotilus(clientId){
    const params = new URLSearchParams()
    params.append('clientid', clientId)
    const url = `${config().heatmapApi}/version?${params.toString()}`
    return fetchWithToken(url, { method: 'GET' })
      .then(response => response.json())
  },
  getColorByDate (date, endpoint) {
    const params = new URLSearchParams()
    params.append('date', date)
    const url = `${config().heatmapApi}/${endpoint}?${params.toString()}`
    return fetchWithToken(url, { method: 'GET' })
      .then(response => response.json())
      .then(result => {
        return result.map((clientResult) => {
          return {
            ...clientResult, endpoint
          }
        })
      })
  },
  callEndpointsByDate(endpoints, date){

    const endpointsPromise = endpoints.map(({ endpoint }) => {
      return HeatmapService.getColorByDate(date, endpoint)
    })
    return HeatmapService.structureHeatmapDatas(endpointsPromise)
  },
  callEndpointsByClientId(endpoints, clientId){
    const endpointsPromise = endpoints.map(({ endpoint }) => {
      return HeatmapService.getColorByClientId(clientId, endpoint)
    })
    return HeatmapService.structureHeatmapDatas([HeatmapService.getNotilus(clientId), ...endpointsPromise])
  },
  structureHeatmapDatas (endpointsPromise){
    return Promise.all(endpointsPromise).then((result) => {
      const flat = result.flat()
      const grouped = Object.values(groupby(flat, 'clientId'))
      return grouped.reduce((allfeatures, fonctionnalites) => {

        const mergedByClient = fonctionnalites.reduce((featuresByClient, fonctionnalite) => {
          const endpoint = fonctionnalite.endpoint
          const color = fonctionnalite.color
          const count = fonctionnalite.nombre
          const serial = fonctionnalite.serial
          const version = result[0].version
          const specifique = result[0].specifique
          const clientId = fonctionnalite.clientId
          const idEnseigne = fonctionnalite.idEnseigne
          const clientName = fonctionnalite.name
          const sousFonctionnalites = fonctionnalite.sousFonctionnalites || []
          return {
            ...featuresByClient, ...endpoint === 'objectifs' &&
            { version: version, specifique: specifique }, ...endpoint === 'cadencierday' &&
            { countCadencier: count }, ...endpoint === 'etiquettes' &&
            { countEtiquettes: count }, ...endpoint === 'photos' &&
            { countPhotos: count }, ...endpoint === 'commandes' &&
            { countCommandes: count },
            [endpoint]: color, clientId, idEnseigne, serial, clientName, sousFonctionnalites
          }
        }, {})
        return [...allfeatures, mergedByClient]
      }, [])
    })
  }
}
