import { getHexnodeInfos, getHexnodeDevAppVersion, getSynchroInfos } from '@/services/HexnodeService'

export default {
  state: {
    hexnodeInfos: [],
    hexnodeDevAppVersion: [],
    synchroInfos: []
  },
  mutations: {
    setHexnodeInfos(state, hexnodeInfos){
      state.hexnodeInfos = hexnodeInfos
    },
    setHexnodeDevAppVersion(state, hexnodeDevAppVersion){
      state.hexnodeDevAppVersion = hexnodeDevAppVersion
    },
    setSynchroInfos(state, synchroInfos){
      state.synchroInfos = synchroInfos
    }
  },
  actions: {
    fetchHexnodeDevAppVersion ({ commit }) {
      return getHexnodeDevAppVersion().then((hexnodeDevAppVersion)=>{
        commit('setHexnodeDevAppVersion', hexnodeDevAppVersion)
      })
    },
    fetchHexnodeInfos ({ commit }, idHexnode) {
      return getHexnodeInfos(idHexnode).then((hexnodeInfos)=>{
        commit('setHexnodeInfos', hexnodeInfos)
      })
    },
    fetchSynchroInfos({commit}, idClient){
      return getSynchroInfos(idClient)
        .then((synchroInfos)=>{
          commit('setSynchroInfos', synchroInfos)
        })
    }
  }
}
