import moment from 'moment'
import SqlUpdateService from '@/services/SqlUpdateService'
import DatabaseService from '@/services/DatabaseService'

function formatUpdate (update) {
  return {
    ...update,
    date: moment(update.date).format('DD/MM/YYYY [-] HH[h]mm'),
    link: () => SqlUpdateService.download(update.idscript || update.idClientScript),
    sqlContent: () => SqlUpdateService.downloadContent(update.idscript || update.idClientScript),
  }
}

export default {
  state: {
    globalUpdates: [],
    nextVersion: 0,
    clientUpdates: [],
    clientNextVersion: 2,
    dialog: false,
    isNewQuery: false,
    currentEditorQuery: '',
    currentQueryType: '',
    currentQueryBeta: false,
    basicEditorQuery: '',
    snackbarMessage: '',
    snackbarColor: '',
    snackbarState: false,
    isReadonly: false,
    editModeObj: {},
    lastClientUpdate : {},
    latest:{}
  },
  mutations: {
    setGlobalUpdates (state, updates) {
      state.globalUpdates = updates
      state.nextVersion = updates[0].version + 1
    },
    setClientUpdates (state, updates) {
      if (updates.length > 0) {
        state.clientNextVersion = updates[0].version + 1
      }
      state.clientUpdates = updates
    },
    setDialogStatus (state, bool) {
      state.dialog = bool
    },
    setIsNewQueryStatus (state, bool) {
      state.isNewQuery = bool
    },
    setCurrentEditorQuery (state, query) {
      state.currentEditorQuery = query
    },
    setCurrentQueryType (state, type) {
      state.currentQueryType = type
    },
    setBasicEditorQuery (state, query) {
      state.basicEditorQuery = query
    },
    setSnackbarMessage (state, message) {
      state.snackbarMessage = message
    },
    setSnackbarColor (state, color) {
      state.snackbarColor = color
    },
    setSnackbarState (state, boolean) {
      state.snackbarState = boolean
    },
    setCurrentQueryBeta (state, bool) {
      state.currentQueryBeta = bool
    },
    setIsReadonly (state, bool) {
      state.isReadonly = bool
    },
    setEditModeObj (state, obj) {
      state.editModeObj = obj
    },
    setLastClientUpdate (state, obj) {
      state.lastClientUpdate = obj
    },
    setLatestDatabase(state, latest){
      state.latest = latest
    }
  },
  actions: {
    fetchGlobalUpdates ({ commit }) {
      return SqlUpdateService.get().then(updates => {
        const newUpdates = updates
          .map(formatUpdate)
          .sort((a, b) => (a.version > b.version ? -1 : 1))
        commit('setGlobalUpdates', newUpdates)
      })
    },
    fetchClientUpdates ({ commit }, serial) {
      return SqlUpdateService.getClient(serial).then(
        updates => {
          const clientUpdates = updates
            .map(formatUpdate)
            .sort((a)=> (a.beta === false ? -1 : 1))
            .sort((a, b) => (a.version > b.version ? -1 : 1))
          commit('setLastClientUpdate', clientUpdates[0])
          commit('setClientUpdates', clientUpdates)
        })
    },
    uploadGlobalUpdate ({ dispatch }, { file, version }) {
      SqlUpdateService.upload(file, version).then(() => {
        dispatch('fetchGlobalUpdates')
        dispatch('addNotification', { text: 'Votre mise à jour a bien été envoyée', color: 'green' })
      }).catch((error) => {
        console.error(error)
        dispatch('addNotification', { text: 'Votre mise à jour n\'a pas été envoyée :( ', color: 'red' })
      })
    },
    uploadClientUpdate ({ dispatch }, { file, version, serial, beta }) {
      SqlUpdateService.uploadClient(
        file, version, serial, beta).then(() => {
        dispatch('fetchClientUpdates', serial)
        dispatch('addNotification', { text: 'Votre mise à jour a bien été envoyée', color: 'green' })
      }).catch((error) => {
        console.error(error)
        dispatch('addNotification', { text: 'Votre mise à jour n\'a pas été envoyée :( ', color: 'red' })
      })
    },
    toggleDialog ({ commit }, bool) {
      commit('setDialogStatus', bool)
    },
    toggleIsNewQuery ({ commit }, bool) {
      commit('setIsNewQueryStatus', bool)
    },
    setCurrentEditorQuery ({ commit }, query) {
      commit('setCurrentEditorQuery', query)
    },
    setCurrentQueryType ({ commit }, type) {
      commit('setCurrentQueryType', type)
    },
    setBasicEditorQuery ({ commit }, query) {
      commit('setBasicEditorQuery', query)
    },
    setSnackbarMessage ({ commit }, message) {
      commit('setSnackbarMessage', message)
    },
    setSnackbarColor ({ commit }, color) {
      commit('setSnackbarColor', color)
    },
    setSnackbarState ({ commit }, boolean) {
      commit('setSnackbarState', boolean)
    },
    setCurrentQueryBeta ({ commit }, bool) {
      commit('setCurrentQueryBeta', bool)
    },
    setIsReadonly ({ commit }, bool) {
      commit('setIsReadonly', bool)
    },
    setEditModeObj ({ commit }, obj) {
      commit('setEditModeObj', obj)
    },
    setLastClientUpdate({ commit }, obj) {
      commit('setLastClientUpdate', obj)
    },
    getLatestDatabase({commit}, clientId){
      return DatabaseService.getLatest(clientId)
        .then((res)=>{
          commit('setLatestDatabase', res[0])
        })
    }
  }
}
