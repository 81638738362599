import Vue from 'vue'
import Vuex from 'vuex'
import customers from './modules/customer'
import databases from './modules/databases'
import notifications from './modules/notifications'
import ocr from './modules/ocr'
import home from './modules/home'
import heatmap from './modules/heatmap'
import hexnode from './modules/hexnode'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    customers,
    databases,
    ocr,
    notifications,
    home,
    heatmap,
    hexnode
  }
})


