import config from '@/utils/ApiUtils'
import { fetchWithToken } from '@/utils/Keycloak'

export default {
  get () {
    return fetchWithToken(config().imageBackend, {
      method: 'GET'
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        return response
      })
  },
  getCalendar () {
    return fetchWithToken(config().imageBackend + 'calendar', {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },

  getCalendarByClient (clientid) {
    return fetchWithToken(config().imageBackend + 'calendar?clientid=' + clientid, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  getByDateAndClient (date, clientid) {
    return fetchWithToken(config().imageBackend + '?clientid=' + clientid + '&date=' + date, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },

  getByDate (date) {
    return fetchWithToken(config().imageBackend + '?date=' + date, {
      method: 'GET'
    })
      .then(response => {
        return response.json()
      })
      .then(response => {
        return response
      })
  },

  getImg (img) {
    return fetchWithToken(config().imageBackend + img, {
      method: 'GET'
    })
      .then(response => {
        return response.blob()
      })
      .then(response => {
        return response
      })
  }
}
