import { render, staticRenderFns } from "./Toolbars.vue?vue&type=template&id=cff585b6&scoped=true"
import script from "./Toolbars.vue?vue&type=script&lang=js"
export * from "./Toolbars.vue?vue&type=script&lang=js"
import style0 from "./Toolbars.vue?vue&type=style&index=0&id=cff585b6&prod&lang=scss"
import style1 from "./Toolbars.vue?vue&type=style&index=1&id=cff585b6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cff585b6",
  null
  
)

export default component.exports