import '@babel/polyfill';
import Vue from 'vue';
import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify/lib';
import './plugins/echarts';
import App from './App.vue';
import router from '@/router';
import moment from 'moment';
import store from './store/store';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'

Vue.use(Vuetify);

Vue.config.productionTip = false;
const opts = {
  icons: {
    iconfont: 'md',
  },
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#376374',
        secondary: '#8AC3D5',
      },
    },
  },
};
moment.locale('fr');

// eslint-disable-next-line
// Vue.config.devtools = process.env.NODE_ENV === "development";

Vue.use(VueKeyCloak, {
  config: {
    clientId: 'front',
    realm: 'crm',
    url: 'https://keycloak.hublot.io',
  },
  init: { onLoad: 'login-required', checkLoginIframe: false },
  onReady: kc => {
    store.dispatch('login', kc)

    new Vue({
      router,
      store,
      vuetify: new Vuetify(opts),
      render: h => h(App),
    }).$mount('#app')
  },
})



