import ChatMessageService from '@/services/ChatMessageService'
import CustomerService from '@/services/CustomerService'
import DatabaseService from '@/services/DatabaseService'
import ImageService from '@/services/ImageService'
import LogService from '@/services/LogService'
import SmsService from '@/services/SmsService'
import moment from 'moment'
export default {

  state: {
    databases: [],
    clientCalendarDatabases: [],
    allDatabases: [],
    databaseCalendar: [],
    logs: [],
    imagesCalendar: [],
    images: [],
    chats: [],
    pickerDate: moment().format('YYYY-MM-DD'),
    sms: 0,
    app: 'hublot',
    levels: [
      { level: 'INFO', color: '#8ac3d5' },
      { level: 'ERROR', color: '#ef9a9a' },
      { level: 'DEBUG', color: '#cfcfcf' },
    ],
    navigationDrawerVisibility: true,
    databaseLoader: false,
  },
  getters: {
    currentApp (state) {
      return state.app
    }
  },
  mutations: {
    setAllDatabases (state, databases) {
      state.allDatabases = databases
    },
    setDatabaseCalendar (state, databases) {
      state.databaseCalendar = databases
    },
    setDatabases (state, databases) {
      state.databases = databases
    },
    setLogs (state, logs) {
      state.logs = logs
    },
    setSms (state, sms) {
      state.sms = sms
    },
    setImagesCalendar (state, images) {
      state.imagesCalendar = images
    },
    setImages (state, images) {
      state.images = images
    },
    setPickerDate (state, date) {
      state.pickerDate = date
    },
    setNavigationDrawerVisibility (state, bool) {
      state.navigationDrawerVisibility = bool
    },
    setDatabaseLoader (state, bool) {
      state.databaseLoader = bool
    },
    setApp (state, app) {
      state.app = app
    }
  },
  actions: {
    setDatabases ({ commit, rootState }) {
      commit('setDatabaseLoader', true)
      const selectedCustomer = rootState.customers.selected
      if (selectedCustomer.clientId) {
        DatabaseService.getCalendarByClient(selectedCustomer.clientId).then(databases => {
          commit('setDatabaseCalendar', databases)
        })
        DatabaseService.getByDateAndClient(rootState.home.pickerDate, selectedCustomer.clientId).then(databases => {
          commit('setDatabases', databases)
          commit('setDatabaseLoader', false)
        })
      } else {
        DatabaseService.getCalendar().then(databases => {
          commit('setDatabaseCalendar', databases)
        })
        DatabaseService.getByDate(rootState.home.pickerDate).then(databases => {
          commit('setDatabases', databases)
          commit('setDatabaseLoader', false)
        })
      }

    },
    setHomeDate ({ commit }, { day }) {
      commit('setPickerDate', day)
    },
    setHomeLogs ({ commit, rootState }) {
      // TEMPORARY WHILE THERE ARE NO LOGS SERVICE FOR CARNOT
      if(rootState.home.app === 'carnot')
        commit('setLogs', [])
      else{
        LogService.get().then((rawLogs) => {
          const logs = rawLogs.map((log) => {
            return Object.assign({}, log, {
              color: rootState.home.levels.find(
                (level) => level.level === log.level.toUpperCase()
              ).color,
              time: moment(log.time).format('DD/MM/YYYY'),
            });
          });

          const selectedCustomer = rootState.customers.selected
          const filtered = logs
            .filter(log => {
              const serial = selectedCustomer.serial || log.serial
              const isFromCustomer = serial === log.serial
              const isToday = moment(log.occurredAt).isSame(rootState.home.pickerDate, 'day')
              return isFromCustomer && isToday
            })
          commit('setLogs', filtered)
        })
      }
    },
    setHomeImages ({ commit, rootState }) {
      const selectedCustomer = rootState.customers.selected
      if (selectedCustomer.clientId) {
        ImageService.getCalendarByClient(selectedCustomer.clientId).then(images => {
          commit('setImagesCalendar', images)
        })

        ImageService.getByDateAndClient(rootState.home.pickerDate, selectedCustomer.clientId).then(images => {
          commit('setImages', images)
        })
      } else {
        ImageService.getCalendar().then(images => {
          commit('setImagesCalendar', images)
        })
        ImageService.getByDate(rootState.home.pickerDate).then(images => {
          commit('setImages', images)
        })
      }
    },
    setApp ({ commit, dispatch }, app) {
      commit('setApp', app)
      CustomerService.get().then((customers) => {
        dispatch('setCustomers', customers);
        dispatch('fetchGlobalUpdates');
        dispatch('setSelectedCustomer', {});
        dispatch('setSmsCount');
        dispatch('setDatabases');
        dispatch('setHomeImages');
        dispatch('setHomeLogs');
        ChatMessageService.updateIo()
      });

    },
    setNavigationDrawerVisibility ({ commit }, bool) {
      commit('setNavigationDrawerVisibility', bool)
    },
    setSmsCount ({ commit, rootState }) {
      const selectedCustomer = rootState.customers.selected
      // TEMPORARY WHILE THERE ARE NO SMS SERVICE FOR CARNOT
      if(rootState.home.app === 'carnot')
        commit('setSms', 0)
      else if (selectedCustomer.clientId) {
        SmsService.getByDateAndClient(rootState.home.toda, selectedCustomer.clientId).then(sms => {
          commit('setSms', sms.total)
        })
      } else {
        SmsService.getByDate(rootState.home.pickerDate).then(sms => {
          commit('setSms', sms.total)
        })
      }
    }
  }
}
