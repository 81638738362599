<template>
  <div class="unauthorized">
    <div class="error-message">
      <h3>
        Vous n'êtes pas autorisé à vous connecter ici :
      </h3>
      <p>
        Essayez de vous connecter avec un autre compte, ou contactez notre support technique.
      </p>
    </div>
    <v-btn color="error" @click="logout()">
      se déconnecter
    </v-btn>
  </div>
</template>
<script>
import { logout } from '@/utils/Keycloak'
export default {
  methods: {
    logout() {
      logout()
    }
  }
}
</script>

<style lang="scss" scoped>
.unauthorized {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.error-message {
  padding: 20px;
  border: 1px solid #fefefe;
  margin: 20px;
}
</style>
