import deepClone from 'lodash.clone'
export default {
  data() {
    return {
      internalValue: { ...Object.freeze(this.value) }
    }
  },
  props: ['value'],
  watch: {
    value: function (newVal) { // watch it
      this.internalValue = { ...Object.freeze(newVal) }
    }
  },
  methods: {
    updateValue: function (updatedValue) {
      if (updatedValue) {
        this.internalValue = deepClone(updatedValue)
      }
      this.$emit('input', Object.freeze(this.internalValue))
    }
  }
}
