<template>
  <v-row class="select-customer mb-2 mt-1 ml-1 mr-3">
    <v-autocomplete :value="selectedCustomer" dense filled return-object :items="customersType" placeholder="Rechercher" item-text="name" @click:clear="clearCustomer" clearable @change="updateClient">
      <template slot="selection" slot-scope="{ item }">
        <v-row justify="start">
          <v-col>
            <span class="name-selected">{{ item.name }}</span>
          </v-col>
        </v-row>
      </template>
      <template slot="item" slot-scope="{ item }">
        <v-row no-gutters justify="start">
          <img class="logo" :src="logoEnseigne(item.idenseigne)" alt="" />
          <v-col class="full-name">
            <span>{{ item.name }}</span>
          </v-col>
        </v-row>
      </template>
      <template #no-data>
        <v-row no-gutters>
          <v-alert :value="true" icon="warning">
            Aucun client
          </v-alert>
        </v-row>
      </template>
    </v-autocomplete>
     <v-row justify="start" no-gutters v-if="selectedCustomer.clientId" class="d-md-flex d-sm-none d-none d-sm-flex white--text infos-customer ma-6 mt-0">
       N° de série<v-icon @click="copyToClipboard(selectedCustomer.serial, 'Numéro de série')">content_copy</v-icon>
       <span>ID : {{selectedCustomer.clientId}}</span>
     </v-row>
  </v-row>
</template>
<script>
import { getUserProfile } from '../utils/Keycloak';
import InputComponentMixin from '../mixins/InputComponentMixin';
import CustomerService from '../services/CustomerService';
import { mapState } from 'vuex';
import groupby from 'lodash.groupby';

export default {
  mixins: [InputComponentMixin],
  methods: {
    clearCustomer () {
      this.$store.dispatch('setSelectedCustomer', {});
    },
    logoEnseigne (idEnseigne) {
      return CustomerService.getLogo(idEnseigne);
    },
    copyToClipboard(element, value) {
      navigator.clipboard
        .writeText(element)
        .then(() => {
          this.$store.dispatch('addNotification', {
            text: `${value} copié dans votre presse-papier`,
            color: 'green',
          });
        })
        .catch((e) => {
          console.error(e);
          this.$store.dispatch('addNotification', {
            text: `Impossible de copier ${value} dans votre presse-papier`,
            color: 'red',
          });
        });
    },
    updateClient: function (customer = {}) {
      if (customer) {
        this.$store.dispatch('setSelectedCustomer', customer);
        if (customer.idSellsy) {
          this.$store.dispatch('setSellsySelectedCustomer', customer.idSellsy);
        }
      }
    },
  },
  created: function () {
    CustomerService.get().then((customers) => {
      this.$store.dispatch('setCustomers', customers);
    });
    getUserProfile().then((profile) => {
      this.nom = profile.firstName + ' ' + profile.lastName;
    });
  },
  computed: {
    ...mapState({
      customers: (state) => state.customers.list,
      selectedCustomer: (state) => state.customers.selected,
    }),
    customersType: function () {
      let customersFiltered = [];
      const groupedCustomers = groupby(this.customers, 'type');
      const groupedCustomersType = Object.getOwnPropertyNames(
        groupedCustomers
      ).map((props) => {
        return {
          name: props,
        };
      }).sort((a) => {
        if(a === 'Client')
          return 1
        return -1
      })
      groupedCustomersType
        .filter((group) => group.name !== 'null')
        .map((group) => {
          const customerWithGroup = this.customers.filter(
            (customer) => customer.type === group.name
          ).sort((a, b) =>  (a.name > b.name)?1:-1)
          const groupName =
            group.name + ' (' + groupedCustomers[group.name].length + ')';
          customersFiltered.push({ header: groupName }, ...customerWithGroup, {
            divider: true,
          });
        });
      return customersFiltered;
    },
  },
};
</script>
<style lang="scss">
.v-input__slot {
  color: #fafafa !important;
}
.v-select__slot {
  color: #fafafa !important;
}
.v-input__append-inner {
  color: #fafafa !important;
}
.logo {
  width: auto;
  height: 20px !important;
}
.logo-selected {
  filter: opacity(0.5) drop-shadow(0 0 0 #fafafa);
  width: auto;
  height: 20px !important;
}
.name-selected {
  color: #fafafa;
  width: 80%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.full-name {
  color: rgba(0, 0, 0, 0.87) !important;
  text-align: end;

}
.select-customer {
  i::before,
  i::after {
    color: #fafafa !important;
  }
  input::placeholder {
    color: #fafafa !important;
    opacity: 1;
  }
  .v-text-field__details {
    color: #fafafa !important;
  }
  .v-select__selections {
    input {
      color: #fafafa !important;
    }
  }
  .v-input__append-inner {
    button,
    i {
      color: #fafafa !important;
    }
  }
  .v-icon {
    color: #fafafa !important;
  }
}
.infos-customer {
  border: 1px solid #fafafa;
  border-radius:4px;
  padding:2%;
}
</style>
