import { fetchWithToken } from '../utils/Keycloak'
import config from '@/utils/ApiUtils'


export default {
  getLogo (id) {
    // on choisit le logo de l'enseigne du client en fonction de l'idEnseigne
    if (id === 1) {
      return require('@/assets/enseignes/leclerc.png')
    } else if (id === 2) {
      return require('@/assets/enseignes/superu.png')
    } else if (id === 3) {
      return require('@/assets/enseignes/intermarche.png')
    } else if (id === 4) {
      return require('@/assets/enseignes/pecherie.png')
    }else if (id === 5) {
      return require('@/assets/enseignes/carrefour.png')
    } else {
      return require('@/assets/hublot_loader.png')
    }
  },
  insert (client) {
    return fetchWithToken(config().shopbackend, {
      method: 'POST',
      body: JSON.stringify(client)
    })
  },
  update (client) {
    return fetchWithToken(config().shopbackend + client.clientId, {
      method: 'PUT',
      body: JSON.stringify(client)
    })
  },
  get () {
    return fetchWithToken(config().shopbackend, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  getEnseigne () {
    return fetchWithToken(config().shopbackend + 'enseigne', {
      method: 'GET'
    }).then(response => response.json())
  },
  getSellsyCompanies (offset) {
    return fetchWithToken(`${config().shopbackend}sellsy/companies?offset=${offset}`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  getSellsyCompanyById (idCompany) {
    return fetchWithToken(`${config().shopbackend}sellsy/company/${idCompany}`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  createTask (task) {
    return fetchWithToken(`${config().shopbackend}sellsy/task/create`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(task)
    }).then(response => {
      return response.json()
    })
  },
  getTaskLabels () {
    return fetchWithToken(`${config().shopbackend}sellsy/tasks/labels`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    })
  },
  updateTask (data, token) {
    return fetchWithToken(`${config().shopbackend}sellsy/tasks/${data.id}`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'PUT',
      body: JSON.stringify({
        data: data,
        token: token
      })
    }).then(response => {
      return response.json()
    })
  },
  getCustomersAvailableDeviceCapacity(){
    return fetchWithToken(`${config().shopbackend}hexnode/devices/available_capacity`, {
      method: 'GET',
    }).then(response => {
      return response.json()
    })
  }
}
