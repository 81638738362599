import HeatmapService from '@/services/HeatmapService'
import json from '@/assets/heatmap.json'
import customers from '@/store/modules/customer'

export default {
  state: {
    loader: true,
    endpoints: json,
    fonctionnalites: [],
    customersFonctionnalites: [],
    clientFonctionnalites : []
  },
  mutations: {
    addFonctionnalite (state, fonctionnalite) {
      state.fonctionnalites = [...state.fonctionnalites, fonctionnalite]
    },
    setFonctionnalites (state, fonctionnalites) {
      state.fonctionnalites = fonctionnalites
    },
    setNCustomersFonctionnalites(state, fonctionnalites){
      state.customersFonctionnalites = fonctionnalites
    },
    addCustomersFonctionnalites(state, fonctionnalites){
      state.customersFonctionnalites = [...state.customersFonctionnalites, fonctionnalites].sort((a,b)=>(a.clientId > b.clientId ? -1 : 1))
    },
    setCustomerFonctionnalites (state, clientFonctionnalites) {
      state.clientFonctionnalites = clientFonctionnalites
    },
    setLoader (state, loader) {
      state.loader = loader
    }
  },
  actions: {
    fetchNCustomersFonctionnalites({ commit, state }){

      commit('setLoader', true)
      const customersFiltered = customers.state.list.filter((customer)=>customer.type === 'Client')
      const customersSorted = customersFiltered.sort((a,b)=>(a.clientId > b.clientId ? -1 : 1))//.filter(client => client.idHexnode)
      //const firstCustomers = customersSorted.filter((customer, index)=> index >= limits.start && index <= limits.end)
      console.log(customersSorted)
      return customersSorted.reduce((precRequest, currentCustomers) => {
        return precRequest.then((result) => {
          if(result){
            commit('addCustomersFonctionnalites', result[0])
            commit('setLoader', false)
          }
          return HeatmapService.callEndpointsByClientId(state.endpoints, currentCustomers.clientId)
        })
      }, Promise.resolve()).then((result) => {
        commit('addCustomersFonctionnalites', result[0])
        commit('setLoader', false)
      })

    },
    fetchHeatmap ({ commit, state }, date) {

      commit('setLoader', true)
      HeatmapService.callEndpointsByDate(state.endpoints, date)
        .then((merged)=>{
          commit('setCustomerFonctionnalites', merged)
          const mergedSorted = merged.sort((a,b)=>{
            return a.clientId > b.clientId ? -1 : 1
          })
          commit('setFonctionnalites', mergedSorted)
        })
        .then(() => {
          commit('setLoader', false)
        })
    },
    fetchCustomerHeatmap({ commit, state }, clientid){
      commit('setLoader', true)
      HeatmapService.callEndpointsByClientId(state.endpoints, clientid)
        .then((merged)=>{
          commit('setCustomerFonctionnalites', merged)
        }).then(() => {
          commit('setLoader', false)
        })
    },
    getClientFonctionnalites({ commit, state }, clientId){
      const clientFonctionnalites = state.fonctionnalites.find((fonctionnalite) => fonctionnalite.clientId === clientId)
      commit('setCustomerFonctionnalites', [clientFonctionnalites])
    }
  }
}
