<template>
  <v-snackbar
    v-if="showNotification"
    elevation="24"
    v-model="showNotification"
    :color="color"
  >
    {{ notification.text }}
    <template v-slot:action="{ attrs }">
      <v-btn :color="color" v-bind="attrs" text @click="deleteNotification()">
        <v-icon>close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapState } from 'vuex';
export default {
  methods: {
    deleteNotification() {
      this.$store.dispatch('removeNotification', this.notification);
    },
  },
  computed: {
    ...mapState({
      notification(state) {
        const queue = state.notifications.queue;
        if (queue.length > 0) {
          const activeNotification = queue[queue.length - 1];
          setTimeout(() => {
            this.deleteNotification();
          }, 2500);
          return activeNotification;
        }
        return undefined;
      },
    }),
    showNotification() {
      return this.notification !== undefined;
    },
    color() {
      return this.notification.color || 'red';
    },
  },
};
</script>
