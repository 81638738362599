import store from '../store/store'

export default function(){
  const app = store.getters.currentApp
  const currentBackend = (() => {
    if(app === 'carnot')
      return 'https://api.carnot.hublot.io/'
    else
      return 'https://dev.api.hublot.io/'
  })()
  return {
    backend : currentBackend,
    databaseBackend :  currentBackend + 'database/',
    imageApi : currentBackend + 'image',
    shopbackend : currentBackend + 'client/',
    enrollBackend : currentBackend + 'client/enroll',
    integrationEnrollBackend : currentBackend+ 'integration/enroll/',
    heatmapApi: currentBackend+ 'statistiques',
    imageBackend : currentBackend+  'image/',
    infoBackend: currentBackend+ 'info/',
    integrationBackend: currentBackend + 'integration/',
    integrationParseBackend : currentBackend + 'integration/parse/',
    parserBackend: currentBackend + 'parser',
    ocrStatsApi: currentBackend+  'ocr/stats',
    ocrApi: currentBackend+  '/ocr/results',
    sqlbackend: currentBackend+   'sqlupdate/'

  }
}

