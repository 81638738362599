
import config from '@/utils/ApiUtils'
import { fetchWithToken } from '@/utils/Keycloak'


export function getHexnodeDevAppVersion () {
  return fetchWithToken(`${config().shopbackend}hexnode/device/dev`, { method: 'GET' })
    .then(response => response.json())
}
export function getHexnodeInfos (idHexnode) {
  return fetchWithToken(`${config().shopbackend}hexnode/device/${idHexnode}`, { method: 'GET' })
    .then(response => response.json())
}
export function getSynchroInfos(clientId){
  const body = JSON.stringify(['cadencierLine','achats','objectifJour','commandes','cadencier', 'cadencierDay'])
  return fetchWithToken(`${config().shopbackend}diffSynchro/${clientId}`, { method: 'POST',
    body, })
    .then(response => response.json())
}
