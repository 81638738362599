<template>
  <div id="app">
    <v-app id="inspire">
      <template v-if="authorized">
        <Toolbars />
        <v-main class="back-color">
          <v-row justify="center">
            <v-col class="full-height">
              <router-view />
            </v-col>
          </v-row>
        </v-main>
        <NavigationDrawerLeft />
      </template>
      <template v-else>
        <Unauthorized />
      </template>
    </v-app>
    <NotificationProvider />
  </div>
</template>

<script>
import Toolbars from '@/components/Toolbars.vue';
import NavigationDrawerLeft from '@/components/NavigationDrawerLeft.vue';
import Unauthorized from '@/components/Unauthorized';
import NotificationProvider from '@/components/NotificationProvider';
import Vue from 'vue'

export default {
  name: 'App',
  components: {
    Toolbars,
    NavigationDrawerLeft,
    Unauthorized,
    NotificationProvider,
  },
  data () {
    return {
      authorized: false,
    };
  },
  mounted () {
    this.checkAuthorization();
  },
  methods: {
    checkAuthorization () {

      this.authorized = Vue.prototype.$keycloak.hasRealmRole('hublot_admin')

    },
  },
};
</script>

<style>
.v-application {
  background-color: #eeeeee !important;
}
.back-color {
  background-color: #eeeeee;
  margin-top: 3.5%;
  min-height: 100%;
}
.toastr {
  font-family: Arial, Helvetica, sans-serif;
}
#app {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.full-height {
  height: 100%;
}
</style>
