import Vue from 'vue'

export const logout = () => {
  Vue.prototype.$keycloak.logoutFn()
}

export const fetchWithToken = (url, options) => {
  const headers = Object.assign({}, options.headers, {
    Authorization: `Bearer ${Vue.prototype.$keycloak.token}`,
  })

  return fetch(url, Object.assign({}, options, {
    headers,
  }))
}

export const getUserProfile = () => new Promise((resolve, reject) => {
  Vue.prototype.$keycloak.loadUserProfile().success((profile) => {
    resolve(profile)
  }).error((error) => {
    reject(error)
  })
})
