import Router from 'vue-router';
import Vue from 'vue';



Vue.use(Router);
const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import(/* webpackChunkName: "Index" */  '@/components/home/Index.vue'),
    props: true,
  },
  // {
  //   path: '/comparator',
  //   name: 'Comparator',
  //   component: Comparator
  // },
  {
    path: '/integration-checker',
    name: 'Vérification des références',
    component: () => import(/* webpackChunkName: "IntegrationChecker" */  '@/components/IntegrationChecker'),
    props: true,
  },
  {
    path: '/shops',
    name: 'Shops',
    component: () => import(/* webpackChunkName: "Shops" */  '@/components/Shops.vue' ),
  },
  {
    path: '/info',
    name: 'Info',
    component: () => import(/* webpackChunkName: "Info" */  '@/components/Info.vue'),
  },
  {
    path: '/image',
    name: 'Image',
    component: () =>  import(/* webpackChunkName: "Image" */  '@/components/Image'),
  },
  {
    path: '/log',
    name: 'Log',
    component: () => import(/* webpackChunkName: "Log" */  '@/components/Log.vue'),
    props: true,
  },
  {
    path: '/database',
    name: 'Database',
    component: () => import(/* webpackChunkName: "Database" */  '@/components/Database.vue'),
    props: true,
  },
  {
    path: '/sourcedb',
    name: 'Source Database',
    component: () => import(/* webpackChunkName: "DatabaseSource" */ '@/components/DatabaseSource'),
  },
  {
    path: '/integration',
    name: 'Intégration',
    component: () => import(/* webpackChunkName: "Integration" */ '@/components/Integration.vue'),
    props: true,
  },
  {
    path: '/integrate',
    name: 'Integrate',
    component: () =>  import(/* webpackChunkName: "Integrate" */  '@/components/Integrate'),
    props: true,
  },
  {
    path: '/sql-update',
    name: 'SQL Update',
    component: () =>  import(/* webpackChunkName: "SqlUpdate" */ '@/views/SqlUpdate'),
    props: true,
  },
  {
    path: '/enrollment',
    name: 'Enrollment',
    component: () =>  import(/* webpackChunkName: "Enrollment" */ '@/components/Enrollment'),
  },
  {
    path: '/chat',
    name: 'Chat',
    component: () =>  import(/* webpackChunkName: "Chat" */  '@/components/Chat.vue'),
  },
  {
    path: '/parser',
    name: 'Parser',
    component: () =>   import(/* webpackChunkName: "Parser" */'@/components/Parser'),
    props: true,
  },
  {
    path: '/ocr-diagnostic',
    name: 'Ocr Diagnostic',
    component: () =>  import(/* webpackChunkName: "OcrDiagnostic" */'@/components/ocrDiagnostic/OcrDiagnostic'),
    props: true,
  },
];

export default new Router({
  mode: 'history',
  routes,
});
