const logBackend = 'https://api.hublot.io/sms/'

export default {
  getByDate (date) {
    return fetch(logBackend + `recap?date=${date}`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).catch((err) => {
      console.error(err)
      return 0
    })
  },
  getByDateAndClient (date, client) {
    return fetch(logBackend + `recap?date=${date}&client=${client}`, {
      method: 'GET'
    }).then(response => {
      return response.json()
    }).catch((err) => {
      console.error(err)
      return 0
    })
  },
}
